import { GetServerSideProps, GetServerSidePropsContext, NextPage } from "next";
import useTranslation from "next-translate/useTranslation";
import React from "react";

import App from "components/containers/App";
import baseApi from "services/api";
import userApi from "services/api/user";
import { appStoreWrapper } from "stores";
import { uiUpdated } from "stores/features/ui";
import { getCookieHeaders } from "utils/cookie";

const LoginPage: NextPage = () => {
  const { t } = useTranslation();

  return <App authEnabled title={t("containers.auth.loginTitle")} />;
};

export const getServerSideProps: GetServerSideProps =
  appStoreWrapper.getServerSideProps(
    (store) => async (context: GetServerSidePropsContext) => {
      const headers = getCookieHeaders(context);

      store.dispatch(userApi.endpoints.getCurrentUser.initiate({ headers }));
      store.dispatch(uiUpdated({ authMessageType: "signin" }));

      await Promise.all(baseApi.util.getRunningOperationPromises());

      return {
        props: {},
      };
    }
  );

export default LoginPage;
